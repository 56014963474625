import {
  ajaxCall, ajaxUpload
}
  from "../utils";
import "jquery-validation";
import "jquery-validation/dist/additional-methods";
import $ from "jquery";
import CryptoJS from 'crypto-js';
import moment from 'moment';
import module from '../../themes/js/pages/Home/fdcalculator'
const manageScript = () => {

  $(document).ready(function () {

    function encryptData(params, secretKey, ivValue){
      secretKey = CryptoJS.enc.Utf8.parse(secretKey);
      ivValue = CryptoJS.enc.Utf8.parse(ivValue);
      const encryptedData = CryptoJS.AES.encrypt(params, secretKey, {iv :ivValue });
   //   let finalParam = { enc_data : encryptedData.toString() };  
      return encryptedData.toString();  
    }
  
    function decryptData(encUrl, secretKey, ivValue){
      const decryptedData = CryptoJS.AES.decrypt(encUrl, CryptoJS.enc.Utf8.parse(secretKey), {iv :CryptoJS.enc.Utf8.parse(ivValue) });
      let finalParam = { dec_data : decryptedData.toString(CryptoJS.enc.Utf8) };  
      return finalParam;  
    }  
    
    // Custom validation method for letters, spaces, and dots
    $.validator.addMethod('letterswithspacesanddots', function (value, element) {
      return this.optional(element) || /^[a-zA-Z .]*$/.test(value);
    }, 'Please enter letters, spaces, and dots only.');

    $.validator.addMethod("mobileStartingWith6789", function(value, element) {
      return this.optional(element) || /^[6789]/.test(value);
    }, "Mobile number must start with 6, 7, 8, or 9.");
    
    $.validator.addMethod("validEmailDomain", function(value, element) {
      return this.optional(element) || /\b[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+(?:com|in)\b/.test(value);
    }, "Please enter a valid email address.");

    

    $(document).on("click", "#citizen,#women,#form_reset,input[name='tenure']", function(){
      if($(this).attr("id")== "form_reset") {
        $("#tensure_month").html(12); 
        $("#tenure-1").prop("checked", true);
      }
      if($(this).attr("name")== "tenure") $("#tensure_month").html($("input[name='tenure']:checked").val()); 
      fdCalculation();
    });
    $(document).on("change", "#payout,input[type='range']", function(){ 
      fdCalculation();
    });

    $(document).on("focusout", "#fd_amount", function(){ 
      let thisAmnt = ($(this).val().replace(/[^0-9 ]/g,""));
      let thisVal =  Math.round(thisAmnt/ 1000) * 1000;
      $(this).val(formatNumber(thisVal));
      fdCalculation();
    });


    $(document).on("click", "#explore_more_home", function(){ 
      $(".tabs-group__list").each(function(){  
          if($(this).attr("data-tab-active") == "true") { 
            window.location.href= '/'+$(this).attr("data-tab-name");
          }
      });
    });

    // will run when page gets loaded
    if ($('#home_page').val()=="Home" || $('#home_page').val()=="Fixed Deposit" ){
    fdCalculation();
    }
    function fdCalculation(){ 
     
      let is_citizen = $("#citizen").is(":checked")? "Y":"N";
      let gender = $("#women").is(":checked")?"F":"A" ;
      let invest_amount = ($("#fd_amount").val().replace(/[^0-9 ]/g,""));
      let tensure = $("input[name='tenure']:checked").val();
      let payout = $("#payout").val() == "on_maturity" ? "SDR" : "FDR";
      let newDate = new Date();
      let twoDigitMonth = ('0' + (newDate.getMonth() + 1)).slice(-2); 
      let twoDigitDate = ('0' + newDate.getDate()).slice(-2);      
      let reqData = {
        "objcalc": {
            "strComp": "SFL",
            "dblInvamt": invest_amount,
            "CertDate": newDate.getFullYear() + "-" + twoDigitMonth + "-" + twoDigitDate ,
            "strTenure": tensure,
            "strscheme": is_citizen == "Y"?"S":"N",
            "strSCFlag": is_citizen,
            "strSubtype": "Normal",
            "strInvType": payout,
            "strGender": gender,
            "RequestForm" : "SKI"
        }
      };
      //console.log("raw req data: ", reqData);
      
        let secKey = $("#req_sec").val();
        let ivKey  = $("#req_iv").val();
        let secKey_res = $("#res_sec").val();
        let ivKey_res  = $("#res_iv").val();    
        let tokenSecKey = $("#token_sec").val();
        let tokenIv = $("#token_iv").val(); 
        const formattedDateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");
        let tokenStr = "digi@"+formattedDateTime+"Z";
        let encData = encryptData(JSON.stringify(reqData), secKey, ivKey);
        let enc_header = encryptData(tokenStr, tokenSecKey, tokenIv);
        let paramData = {
          enc_header : enc_header, sendData : { "Request" : encData} 
        }


        ajaxCall("/ajax/commaonFdCalc", "POST",paramData, response => { 
        if (response.status == "success") {
            let responseTxt  = response.data.DigiCalculatorResult;
             // console.log("response data -->", responseTxt);
              
            let decDatasArr = responseTxt[0];  let decData;
            // to hide the payout div when on_maturity is selected
            if(payout == "SDR") {
              $("#payout_data").hide(); decData = decDatasArr.SDRCalc[0];
            } 
            else {
              $("#payout_data").show(); decDatasArr = decDatasArr.FDRCalc;
              decDatasArr.map((data, i) => {
                if(formatString(data.Frequency) == $("#payout").val()) decData = data;
              });              
            } 
              //console.log("decData -->>", decData)
            $("#invested_amount").html(formatNumber(decData.MaturityAmount));
            $("#ttl_earnings").html(formatNumber(decData.TotalInterestEarnings));
            $("#interest_payout").html(formatNumber(decData.IntPayAmt));
            $("#interest_rate").html(`Interest Rate ${decData.W_ROI}%`);
            let profitAmnt = parseInt(decData.TotalInterestEarnings) ;
            let ttlAmount  = parseInt(decData.MaturityAmount)+ profitAmnt;
            $("#ttl_receivable").html(formatNumber(ttlAmount));
            let profitPercent = Math.round((profitAmnt*100) / ttlAmount);
            let basePercent = Math.round(( decData.MaturityAmount*100) / ttlAmount);
            if(payout == "FDR") {
              $("#interest_ratio_str").html(`${decData.Frequency} Interest Payout`);
              $("#effective_yield").remove();
            }
            else {
              if($("#effective_yield").length) {
                $("#effective_yield").html(`Effective Yield ${(decData.YieldRate).toFixed(2)}%`);
              } else
                $("#ratio_tab").append(`<p id="effective_yield"> Effective Yield ${(decData.YieldRate).toFixed(2)}% </p>`);
            } 

            $("#fdSubmit").html("Invest Now").removeAttr("disabled");
            const chartData={
              'earnings': profitPercent,
              'investAmount': basePercent
            }
            module.fdCalculator(chartData)
            $('[data-content="true"]').removeClass('no-data')
          $('[action-event="true"]').removeClass('show-err')

        } else {

          $("#invested_amount").html("-");
          $("#ttl_earnings").html("-");
          $("#interest_payout").html("-");
          $("#interest_rate").html(`Interest Rate - Nil`);
          $("#ttl_receivable").html("-");
          $("#fdSubmit").html("No Scheme Available").attr("disabled", "disabled");
          $('[data-content="true"]').addClass('no-data')
          const chartData={
            'earnings': 100,
            'investAmount':0
          }
          $('[action-event="true"]').addClass('show-err')
          module.fdCalculator(chartData)
            console.log("error data -->", response)
        }
      });        
    } 
          // to remove special chars and space in response data
          function formatString(str){
              return  str.toLowerCase().replace("-", "").replace(" ", "");
          }


          // to change the amount to indian number format  
          function formatNumber(num) {
            var n1, n2;
            num = num + '' || '';
            n1 = num.split('.');
            n2 = n1[1] || null;
            n1 = n1[0].replace(/(\d)(?=(\d\d)+\d$)/g, "$1,");
            num = n2 ? n1 + '.' + n2 : n1;
            return num;
          }

    $('#supportform').validate({
      rules: {
        support_name: {
          required: true,
          letterswithspacesanddots: true      
        },
        support_email: {
          required: true,
          validEmailDomain: true
        },
        support_mobileno: {
          required: true,
          digits: true,
          mobileStartingWith6789: true,
          minlength: 10
        }
      },
      messages: {
        support_name: {
          required: "Please Enter Name.",
          letterswithspacesanddots: "Please enter a valid Name."
        },
        support_email: {
          required: "Please Enter Email.",
          validEmailDomain: "Please enter a valid email address."
        },
        support_mobileno: {
          required: "Please Enter Mobile Number.",
          digits: "Please enter only Numbers.",
          mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9.",
          minlength:"Please enter 10 digits."
        }
      },
      submitHandler: function () {
        const messageData = {
          name: $('#support_name').val(),
          email: $('#support_email').val(),
          mobileno: $('#support_mobileno').val(),
          page: $('#home_page').val(),
          is_agent: 0,
          desc: "",
          plan: ""
        }
        const params = {
          Reqdata: messageData,
        };
       
        ajaxCall("/ajax/commonCall", "POST", params, (response) => {
         // console.log('response :47', response);
          if (response.status === "success") {
            $('#supportform').parent().addClass('active');
            $ ("#supportform")[0].reset();
            setTimeout(function () {
              $('#supportform').parent().removeClass('active');
            }, 5000)
          }
        });
      }
    });

    $(document).on("change", "select[name='myselect']", function(){ 
      if ($(this).valid()) {
        $(this).next(".error").remove(); // Remove any existing error messages
      }
    });

    //Support plan form
    $('#supportPlanForm').validate({
      rules: {
        support_name: {
          required: true,
          letterswithspacesanddots: true
        },
        support_email: {
          required: true,
          validEmailDomain: true
        },
        myselect: {
          required: true,
        },
        support_mobileno: {
          required: true,
          digits: true,
          mobileStartingWith6789: true,
          minlength: 10
        }
      },
      messages: {
        support_name: {
          required: "Please Enter Name.",
          letterswithspacesanddots: "Please enter a valid Name."
        },
        support_email: {
          required: "Please Enter Email.",
          validEmailDomain: "Please enter a valid email address."
        },
        myselect: {
          required: "Please Select Plan.",
        },
        support_mobileno: {
          required: "Please Enter Mobile Number.",
          digits: "Please enter only Numbers.",
          mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9.",
          minlength:"Please enter 10 digits."
        }
      },
      submitHandler: function () {
        const messageData = {
          name: $('#support_name').val(),
          email: $('#support_email').val(),
          mobileno: $('#support_mobileno').val(),
          page: $('#home_page').val(),
          is_agent: 0,
          desc: "",
          plan: $('#claim-bonus').val()
        }
        const params = {
          Reqdata: messageData,
        };

        ajaxCall("/ajax/commonCall", "POST", params, (response) => {
          if (response.status === "success") {
            $('#supportPlanForm').parent().addClass('active');
            $("#supportPlanForm")[0].reset();
            setTimeout(function () {
              $('#supportPlanForm').parent().removeClass('active');
            }, 5000)
          }
        });
      }
    });
    
    //Join agent form
    $('#agentform').validate({
      rules: {
        agent_name: {
          required: true,
          letterswithspacesanddots: true
        },
        agent_email: {
          required: true,
          validEmailDomain: true
        },
        agent_mobilno: {
          required: true,
          digits: true,
          mobileStartingWith6789: true,
          minlength: 10
        }
      },
      messages: {
        agent_name: {
          required: "Please Enter Name.",
          letterswithspacesanddots: "Please enter a valid Name."
        },
        agent_email: {
          required: "Please Enter Email.",
          validEmailDomain: "Please enter a valid email address."
        },
        agent_mobilno: {
          required: "Please Enter Mobile Number.",
          digits: "Please enter only Numbers.",
          mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9.",
          minlength:"Please enter 10 digits."
        }
      },
      submitHandler: function () {
        const messageData = {
          name: $('#agent_name').val(),
          email: $('#agent_email').val(),
          mobileno: $('#agent_mobilno').val(),
          page: $('#home_page').val(),
          is_agent: 1,
          desc: "",
          plan: ""
        }
        const params = {
          Reqdata: messageData,
        };

        ajaxCall("/ajax/commonCall", "POST", params, (response) => {
         //console.log('response :172', response);
          if (response.status === "success") {
            $('#agentform').parent().addClass('active');
            $ ("#agentform")[0].reset();
            setTimeout(function () {
              $('#agentform').parent().removeClass('active');
            }, 5000)
          }
        });
      }
    });
    //
    $('#contactForm').validate({
      rules: {
        contact_name: {
          required: true,
          letterswithspacesanddots: true
        },
        contact_email: {
          required: true,
          validEmailDomain: true
        },
        contact_mobilno: {
          required: true,
          digits: true,
          mobileStartingWith6789: true,
          minlength: 10
        },
        contact_desc: {
          required: true,
          maxlength: 200          
        }
      },
      messages: {
        contact_name: {
          required: "Please Enter Name.",
          letterswithspacesanddots: "Please enter a valid Name."
        },
        contact_email: {
          required: "Please Enter Email.",
          validEmailDomain: "Please enter a valid email address."
        },
        contact_mobilno: {
          required: "Please Enter Mobile Number.",
          digits: "Please enter only Numbers.",
          mobileStartingWith6789: "Mobile number must start with 6, 7, 8, or 9.",
          minlength:"Please enter 10 digits."
        },
        contact_desc: {
          required: "Please Enter Description.",
          maxlength :  "Please enter no more than 200 characters."
        }
      },
      submitHandler: function () {
        const messageData = {
          name: $('#contact_name').val(),
          email: $('#contact_email').val(),
          mobileno: $('#contact_mobilno').val(),
          desc: $('#contact_desc').val(),
          page: "support",
          is_agent: 0,
          plan: ""
        }
        const params = {
          Reqdata: messageData,
        };

        ajaxCall("/ajax/commonCall", "POST", params, (response) => {
         //'response :172', response);
          if (response.status === "success") {
            $('#contactForm').parent().addClass('active');
            $ ("#contactForm")[0].reset();
            setTimeout(function () {
              $('#contactForm').parent().removeClass('active');
            }, 5000)
          }
        });
      }
    });
  });
}



const initmanage = () => {
  manageScript();
};
export {
  initmanage
}
